exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dental-bridges-js": () => import("./../../../src/pages/dental-bridges.js" /* webpackChunkName: "component---src-pages-dental-bridges-js" */),
  "component---src-pages-dental-crowns-js": () => import("./../../../src/pages/dental-crowns.js" /* webpackChunkName: "component---src-pages-dental-crowns-js" */),
  "component---src-pages-dental-emergency-js": () => import("./../../../src/pages/dental-emergency.js" /* webpackChunkName: "component---src-pages-dental-emergency-js" */),
  "component---src-pages-dental-implants-js": () => import("./../../../src/pages/dental-implants.js" /* webpackChunkName: "component---src-pages-dental-implants-js" */),
  "component---src-pages-dental-sealants-js": () => import("./../../../src/pages/dental-sealants.js" /* webpackChunkName: "component---src-pages-dental-sealants-js" */),
  "component---src-pages-dentures-js": () => import("./../../../src/pages/dentures.js" /* webpackChunkName: "component---src-pages-dentures-js" */),
  "component---src-pages-gum-disease-js": () => import("./../../../src/pages/gum-disease.js" /* webpackChunkName: "component---src-pages-gum-disease-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invisalign-js": () => import("./../../../src/pages/invisalign.js" /* webpackChunkName: "component---src-pages-invisalign-js" */),
  "component---src-pages-root-canal-treatment-js": () => import("./../../../src/pages/root-canal-treatment.js" /* webpackChunkName: "component---src-pages-root-canal-treatment-js" */),
  "component---src-pages-teeth-whitening-js": () => import("./../../../src/pages/teeth-whitening.js" /* webpackChunkName: "component---src-pages-teeth-whitening-js" */),
  "component---src-pages-tooth-extraction-js": () => import("./../../../src/pages/tooth-extraction.js" /* webpackChunkName: "component---src-pages-tooth-extraction-js" */),
  "component---src-pages-wisdom-tooth-extraction-js": () => import("./../../../src/pages/wisdom-tooth-extraction.js" /* webpackChunkName: "component---src-pages-wisdom-tooth-extraction-js" */)
}

